<template>
  <v-app>
    <v-app-bar fixed dense class color="white">
      <v-toolbar-title class="title primary--text pl-0">
        <v-avatar size="36">
          <v-img :src="ico_url"></v-img>
        </v-avatar>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        color="red"
       href="https://www.youtube.com/watch?v=QTrrLEITlp0"
        class="py-0 mx-2 text-lowercase white--text"
      >more info</v-btn> <v-btn
        color="blue"
        @click="reserve_now = true"
        class="py-0 text-lowercase white--text"
      >become a reporter</v-btn>
    </v-app-bar>
    <v-sheet>
      <v-main class="Main-Frame">
        <div class="my-4 d-sm-flex d-md-none">
          <br />
        </div>

        <v-dialog max-width="420" v-model="reserve_now">
          <v-card max-width="420">
            <RepotersRegistrations></RepotersRegistrations>
          </v-card>
        </v-dialog>
        <v-container class="Main-Container mt-11" style>
          <router-view />
        </v-container>
      </v-main>
    </v-sheet>
  </v-app>
</template>

<script>
import icon from '@/assets/icon.png'
import RepotersRegistrations from '@/components/RepotersRegistrations.vue'
export default {
  name: 'App',
  components: {
    RepotersRegistrations
  },
  data: () => ({
    ico_url: icon,
    reserve_now: false
    //
  }),
  methods: {

  }
}
</script>
<style>
@media only screen and (max-width: 981px) {
  .Main-Container {
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.Main-Container {
  min-height: 96vh;
}
.Main-Frame {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  background-color: #f9f9f9;
}
</style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" lg="7">
        <h4>Beirut Ma bit Mout - Event accross all the countries in support with people who died on August 4, 2020 in Beirut Blast</h4>
        <br />
        <br />
        <v-row class="mt-0">
          <v-col cols="12" sm="12">
            <v-responsive style="border-radius: 6px;" :aspect-ratio="16 / 9">
              <VueTwitchPlayer
    channel="ardkon" width="100%"
  ></VueTwitchPlayer>
            </v-responsive>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="5">
        <h4>Supporting cities</h4>
        <v-slide-group multiple       show-arrows
 class="mx-0">
          <v-slide-item  v-for="n in cities" :key="n.city" v-slot="{ active, toggle }">
            <v-btn
              class="mx-2"
              active-class="purple white--text"
              depressed
              :input-value="active"
              rounded
              @click="show(n); toggle"

            > {{ n.city }}</v-btn>

          </v-slide-item>
        </v-slide-group>
        <span class="font-weight-bold">Country: {{country}}</span><br><br>
        <v-row style="height:72vh; overflow-x: hidden; overflow-y: scroll">
          <v-col cols="12"  v-for="channel in channels" :key="channel">
            <v-responsive style="border-radius: 6px;" :aspect-ratio="16 / 9">
              <VueTwitchPlayer
    :channel="channel"
    width="100%"
  ></VueTwitchPlayer>
            </v-responsive>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store'
import VueTwitchPlayer from 'vue-twitch-player'

export default {
  name: 'Home',

  components: {
    VueTwitchPlayer
  },
  data () {
    return {
      channels: [],
      country: ''
    }
  },
  methods: {
    show (n) {
      console.log(n)
      this.channels = n.channels
      this.country = n.country
    }
  },
  computed: {
    cities () {
      return store.state.ledger.cities
    }
  },
  mounted () {
    store.dispatch('ledger/getCities')
  }
}
</script>

<template>
  <v-container>
    <v-row>
      <v-card-title>Reporters Registration</v-card-title>

      <v-card-subtitle class="text-lowercase">
        <span
          class="font-weight-bold"
        >This Registration will enable you to register to becoming a reporter on the day of the event.</span>
        <br />Reporters will use
        <a href="https://www.twitch.tv/">twitch</a> as an input source. before adding your form please do create a twitch account and then add the necessary details.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field v-model="form.first_name" :counter="10" label="first name" required></v-text-field>
            <v-text-field v-model="form.last_name" :counter="10" label="last name" required></v-text-field>
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.phone_number"
              :counter="15"
              type="tel"
              label="phone number"
              required
            ></v-text-field>
            <v-text-field v-model="form.email_address" label="email address" required></v-text-field>
          </v-col>
          <v-col md="6" cols="12">
            <country-select
              required
              style="color:rgba(118, 118, 118,1);   text-decoration: underline; cursor: pointer"
              v-model="form.stream_country"
              :country="form.stream_country"
              topCountry="LB"
            />
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field v-model="form.stream_city" label="city" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="form.stream_square" label="square" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="form.stream_channel_name" label="twitch channel name" required></v-text-field>
          </v-col>
          <v-col md="12" cols="12">
            <span class="red--text font-weight-bold">{{ message }}</span>
            <br />
          </v-col>
        </v-row>
        <v-progress-linear color="info accent-4" indeterminate rounded height="6" v-if="submited"></v-progress-linear>
        <br />
        <v-btn class="mr-4" color="primary" @click="submitReservation">submit</v-btn>
      </v-card-text>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'

export default {
  name: 'reporters-registrations',
  data: () => ({
    submited: false,
    form: {
      first_name: '',
      last_name: '',
      email_address: '',
      phone_number: '',
      stream_square: '',
      stream_country: '',
      stream_city: '',
      stream_channel_name: '',
      stream_type: 'live-reporters'
    },
    message: ''
  }),
  methods: {
    submitReservation () {
      var submitForm = this.form
      if (this.form.first_name === '') {
        this.message = 'add your first name'
        this.submited = false
      }
      if (this.form.last_name === '') {
        this.message = 'add your last name'
        this.submited = false
      }
      if (this.form.email_address === '') {
        this.message = 'add your email address'
        this.submited = false
      }
      if (this.form.phone_number === '') {
        this.message = 'add your phone number'
        this.submited = false
      }
      if (this.form.stream_country === '') {
        this.message = 'add your country'
        this.submited = false
      }
      if (this.form.stream_city === '') {
        this.message = 'add your city'
        this.submited = false
      }
      if (this.form.stream_square === '') {
        this.message = 'add your square'
        this.submited = false
      }
      if (this.form.stream_channel_name === '') {
        this.message = 'add your twitch channel name'
        this.submited = false
      }
      if (this.form.stream_channel_name !== '' && this.form.stream_square !== '' && this.form.stream_city !== '' && this.form.stream_country !== '' && this.form.phone_number !== '' && this.form.email_address !== '' && this.form.last_name !== '' && this.form.first_name !== '') {
        this.submited = true

        axios
          .post('https://techard.net/stream_info?basics=reunite2', submitForm)
          .then(response => {
            console.log(response)
            this.message = response.data.message
            this.submited = false
          })
      }
    }
  }
}
</script>

import axios from 'axios'

const actions = {

  async getCities ({ commit }) {
    return axios
      .get('https://techard.net/cities?basics=reunite2', {})
      .then((response) => {
        commit('CITIES', response.data.Info)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  setBidDialogFalse ({ commit }) {
    commit('SET_BID_DIALOG_FALSE')
  }
}
const ledger = {
  namespaced: true,
  state: () => ({
    cities: []
  }),
  mutations: {
    CITIES (state, data) {
      for (let i = 0; i < data.length; i++) {
        state.cities.push(data[i])
      }
    }
  },
  getters: {
  },
  actions
}

export default ledger
